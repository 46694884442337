#root-nav {
  height: 100%;

  display: flex;
  flex-direction: column;
}

#entrance-img {
  width: 100%;
}

#root-nav-header {
  margin: 1rem;
}

#root-nav-body {
  display: flex;
  align-self: center;
  justify-content: center;
  flex-grow: 1;
}

#root-nav-body-image {
  width: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#root-nav-body-content {
  max-width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#root-nav-title {
  font-size: 24pt;
}

#root-nav-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.root-button {
  width: 18rem;

  margin: 0.7rem;
  padding: 0.7rem;
  
  background-color: #f36b24;
  color: white;
  font-size: 16pt;
  text-align: center;
  border-radius: 1rem;
  border: 1px solid #d15f22;
  cursor: pointer;
  text-decoration: none;

  transition: background-color 0.3s;

  &:hover {
    text-decoration: none;
    color: white;
    background-color: #f38347;
  }
}

.grey-root-button {
  background-color: #aaa;
  border: 1px solid #555;

  &:hover {
    text-decoration: none;
    color: white;
    background-color: #ccc;
    border: 1px solid #aaa;
  }
}
